<template>
  <div class="home_flex">
    <div class="home_grid">
      <section>
        <SidebarComponent />
      </section>
      <section>
        <div class="theTopNavbar">
          <NavbarComponent />
        </div>
        <div class="container">
          <router-view />
        </div>
        <FooterComponent />
      </section>
    </div>
  </div>
</template>

<script>
import NavbarComponent from "../../components/backoffice/navbar-component.vue";
import FooterComponent from "../../components/backoffice/footer.vue";
import SidebarComponent from "../../components/backoffice/sidebar-component.vue";
export default {
  components: {
    NavbarComponent,
    FooterComponent,
    SidebarComponent
  },

  data() {
    return {
    };
  },

  // then, in the parent component,
  // watch the `$route` to determine the transition to use
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },

  mounted() {
    // this.setCurrentUser();
  },

  methods: {

    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
      location.reload(true);
    },
  },
};
</script>


<style scoped>
.home_grid {
  width: 100%;
}

.home_grid > section:first-child {
  width: 20%;
  height: 100vh;
  background-color: #17351E; 
  color: whitesmoke;
  padding: 30px 20px 60px 20px;
  font-size: 1em;

  position: fixed;
  top: 0px;
  bottom: 0px;
  overflow: auto;
}
.home_grid > section:nth-child(2) {
  margin-left: 20%;
  padding-top: 60px;
  width: 80%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}

.side-link ul {
  list-style: none;
  padding: 0px;
}
.side-link ul li {
  border-bottom: 1px solid grey;
  padding-top: 30px;
  padding-bottom: 10px;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 300;
}
.side-link .active {
  border-left: 6px solid #DDD0A9;
  color: grey !important;
  font-weight: 300;
}
.side-link ul li:hover {
  padding-left: 30px;
  transition: 0.5s;
  color: grey;
}

.theTopNavbar {
  position: fixed;
  top: 0px;
  left: 20%;
  right: 0px;
  z-index: 100;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .home_grid > section:first-child {
    display: none;
    width: 0px;
    height: 0px;
    padding: 0px;
    position: relative;
  }
  .home_grid > section:nth-child(2) {
    margin-left: 0px;
    padding-top: 50px;
    width: 100%;
  }

  .theTopNavbar {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .home_grid > section:first-child {
    display: none;
    width: 0px;
    height: 0px;
    padding: 0px;
    position: static;
  }
  .home_grid > section:nth-child(2) {
    margin-left: 0px;
    padding-top: 50px;
    width: 100%;
  }

  .theTopNavbar {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
  }
}
</style>